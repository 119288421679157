<template>
  <div>
    <v-form @submit.prevent="submitResponse">
      <p class="mb-2">
        Check the box in the Valid column for each set of quantum numbers that is appropriate for an
        electron in the
        <stemble-latex content="$n=2$" />
        energy level:
      </p>

      <v-simple-table class="pl-7">
        <thead>
          <tr>
            <th style="font-size: 15px">
              <stemble-latex content="$\text{n}$" />
            </th>
            <th style="font-size: 15px">
              <stemble-latex content="$\ell$" />
            </th>
            <th style="font-size: 15px">
              <stemble-latex content="$\text{m}_{\ell}$" />
            </th>
            <th style="font-size: 15px">
              <stemble-latex content="$\text{m}_{\text{s}}$" />
            </th>
            <th style="font-size: 15px">
              <stemble-latex content="$\textbf{Valid}$" />
            </th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>
              <stemble-latex :content="n1" />
            </td>
            <td>
              <stemble-latex :content="l1" />
            </td>
            <td>
              <stemble-latex :content="ml1" />
            </td>
            <td>
              <stemble-latex :content="ms1" />
            </td>
            <td>
              <v-checkbox v-model="inputs.input1" value="a" />
            </td>
          </tr>

          <tr>
            <td>
              <stemble-latex :content="n2" />
            </td>
            <td>
              <stemble-latex :content="l2" />
            </td>
            <td>
              <stemble-latex :content="ml2" />
            </td>
            <td>
              <stemble-latex :content="ms2" />
            </td>
            <td>
              <v-checkbox v-model="inputs.input1" value="b" />
            </td>
          </tr>

          <tr>
            <td>
              <stemble-latex :content="n3" />
            </td>
            <td>
              <stemble-latex :content="l3" />
            </td>
            <td>
              <stemble-latex :content="ml3" />
            </td>
            <td>
              <stemble-latex :content="ms3" />
            </td>
            <td>
              <v-checkbox v-model="inputs.input1" value="c" />
            </td>
          </tr>

          <tr>
            <td>
              <stemble-latex :content="n4" />
            </td>
            <td>
              <stemble-latex :content="l4" />
            </td>
            <td>
              <stemble-latex :content="ml4" />
            </td>
            <td>
              <stemble-latex :content="ms4" />
            </td>
            <td>
              <v-checkbox v-model="inputs.input1" value="d" />
            </td>
          </tr>
        </tbody>
      </v-simple-table>
    </v-form>
  </div>
</template>

<script>
import DynamicQuestionMixin from '../../mixins/dynamic-question';
import StembleLatex from '@/tasks/components/StembleLatex';

export default {
  name: 'Question250',
  components: {
    StembleLatex,
  },
  mixins: [DynamicQuestionMixin()],
  data() {
    return {
      inputs: {
        input1: [],
      },
    };
  },
  computed: {
    n1() {
      return '$2$';
    },
    l1() {
      return '$1$';
    },
    ml1() {
      return '$'.concat(this.taskState.getValueBySymbol('ml1').content.value.toString(), '$');
    },
    ms1() {
      return '$-1/2$';
    },
    n2() {
      return '$2$';
    },
    l2() {
      return '$0$';
    },
    ml2() {
      return '$'.concat(this.taskState.getValueBySymbol('ml2').content.value.toString(), '$');
    },
    ms2Number() {
      return this.taskState.getValueBySymbol('ms2Number').content;
    },
    ms2() {
      if (this.ms2Number.value === -1) {
        return '$-1/2$';
      } else if (this.ms2Number.value === 0) {
        return '$0$';
      } else if (this.ms2Number.value === 1) {
        return '$+1/2$';
      } else {
        return '';
      }
    },
    n3() {
      return '$'.concat(this.taskState.getValueBySymbol('n3').content.value.toString(), '$');
    },
    l3() {
      return '$1$';
    },
    ml3() {
      return '$0$';
    },
    ms3Number() {
      return this.taskState.getValueBySymbol('ms3Number').content;
    },
    ms3() {
      if (this.ms3Number.value === 0) {
        return '$0$';
      } else if (this.ms3Number.value === 1) {
        return '$+1/2$';
      } else {
        return '';
      }
    },
    n4() {
      return '$2$';
    },
    l4() {
      return '$1$';
    },
    ml4() {
      return '$'.concat(this.taskState.getValueBySymbol('ml4').content.value.toString(), '$');
    },
    ms4() {
      return '$+1/2$';
    },
  },
};
</script>
